import React from 'react'

import { Sheet, AccordionGroup } from '@mui/joy'
import { accordionDetailsClasses } from '@mui/joy/AccordionDetails'
import { accordionSummaryClasses } from '@mui/joy/AccordionSummary'

import Basic from './Basic'
import Additional from './Additional'
import Sharing from './Sharing'
import Submit from '../Submit'
import Balance from './Balance'
import { useSelector } from 'react-redux'

const accordionStyles = {
  [`& .${accordionSummaryClasses.indicator}`]: {
    transition: '0.2s',
  },
  [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
    transform: 'rotate(45deg)',
  },
  [`& .${accordionSummaryClasses.button}:hover`]: {
    bgcolor: 'transparent',
  },
  [`& .${accordionDetailsClasses.content}`]: {
    gridGap: '10px',
    boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
    padding: '1.2rem',
    paddingBlock: '1rem',
  },
  [`& .${accordionSummaryClasses.button}`]: {
    padding: '1.2rem',
    paddingBlock: '0.65rem',
  },
  [`& .${accordionSummaryClasses.button}:last-child`]: {
    borderBottom: 'none',
  },
}

const Form = ({ isLoading }) => {
  const profile = useSelector((state) => state.auth.profileData.profile)
  const isRoleGranted = profile?.role_id === 1 || profile?.role_id === 2

  return (
    <>
      <Sheet
        variant="outlined"
        sx={{
          border: '1px solid',
          borderRight: 'none',
          borderLeft: 'none',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          backgroundColor: 'transparent',
          overflow: 'hidden',
        }}
      >
        <AccordionGroup size="sm" sx={accordionStyles}>
          <Basic />

          {isRoleGranted && <Balance />}

          <Additional />
        </AccordionGroup>
      </Sheet>

      <Sheet
        variant="outlined"
        sx={{
          border: '1px solid',
          borderRight: 'none',
          borderLeft: 'none',
          borderColor: 'divider',
          transition: 'transform 0.4s',
          backgroundColor: 'transparent',
          overflow: 'hidden',
        }}
      >
        <AccordionGroup size="sm" sx={accordionStyles}>
          <Sharing />
        </AccordionGroup>
        <Submit isLoading={isLoading} />
      </Sheet>
    </>
  )
}

export default Form
