import React, { useContext, useState } from 'react'

import { Select } from 'antd'
import { useFilter } from '../../../../../hooks/filter'
import { FiltersContext } from '../../../../../hocs/FiltersProvider'
import { useSelector } from 'react-redux'

const ReportType = ({ setWithAdvancedSearch }) => {
  const { filter, updateFilter, resetFilter } = useFilter()
  const [report, setReport] = useState(filter.report_type || 0)
  const profile = useSelector((state) => state.auth.profileData.profile)

  const isUserAdmin = profile && profile.role_id === 1

  const reportTypes = isUserAdmin
    ? [
        { value: 0, label: 'General' },
        { value: 1, label: 'Advanced' },
      ]
    : [{ value: 0, label: 'General' }]

  const { setterVpn, setterTor, setterUniq } = useContext(FiltersContext)

  const setToDefault = () => {
    setterVpn('0')
    setterUniq('0')
    setterTor('0')
    resetFilter()
  }

  const handleChange = (newValue) => {
    newValue === 0 && setToDefault()
    if (reportTypes.some((item) => !item.disabled && item.value === newValue)) {
      setWithAdvancedSearch(newValue)
    } else {
      setReport(newValue)
    }
    updateFilter('report_type', newValue)
  }

  return (
    <Select
      defaultValue={report}
      maxTagCount={'responsive'}
      style={{ marginRight: '12px', flex: 1 }}
      options={reportTypes}
      onChange={handleChange}
    />
  )
}

export default ReportType
