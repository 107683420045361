import React, { useEffect, useState } from 'react'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useColorScheme,
} from '@mui/joy'

import { Add, PersonRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Layout } from 'antd'

import { useSelector } from 'react-redux'

import { useAppDispatch } from '../../../../../store'
import { getAllPublishers } from '../../../../../store/publishers'
import ListItems from './ListItems'
import Form from './Form'

const { Content, Sider } = Layout

const Publishers = ({ selectedPublisherId, setSelectedPublisherId }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [accordionIndex, setAccordionIndex] = useState(0)

  const siderStyle = {
    overflow: 'auto',
    height: '75vh',
    position: 'sticky',
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarGutter: 'stable',
    background: 'transparent',
    borderRight: '1px solid var(--joy-palette-divider)',
  }

  useEffect(() => {
    dispatch(getAllPublishers())
  }, [dispatch])

  const rows = useSelector((state) => state.publishers.getAllData?.rows || [])
  const { mode } = useColorScheme()

  return (
    <Accordion
      expanded={accordionIndex === 0}
      onChange={(_, expanded) => {
        setAccordionIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('sidebar_navigation.publishers')}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Layout hasSider style={{ background: 'transparent' }}>
          <Sider style={siderStyle}>
            <ListItems
              items={rows}
              selectedItem={selectedPublisherId}
              setSelectedItem={setSelectedPublisherId}
            />
          </Sider>
          <Layout
            style={{
              height: '75vh',
              background: mode === 'dark' ? 'transparent' : '#00000005',
            }}
          >
            <Content style={{ overflow: 'auto' }}>
              <Form selectedItem={selectedPublisherId} />
            </Content>
          </Layout>
        </Layout>
      </AccordionDetails>
    </Accordion>
  )
}

export default Publishers
