import React, { useEffect } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../store'
import { getDomains } from '../../../../../store/domains'
import InputGroupVertical from '../../../../components/InputGroup'

const Domains = ({ selectedDomain, setSelectedDomain, values }) => {
  const dispatch = useAppDispatch()

  const isLoadingDomains = useSelector((state) => state.domains.isLoading)

  useEffect(() => {
    dispatch(getDomains())
  }, [dispatch])

  const items = values?.map((i, k) => ({ label: i, value: k })) || []

  return (
    <>
      <InputGroupVertical text="domain" sxText={{ fontWeight: 'bold' }}>
        <Select
          maxTagCount={'responsive'}
          style={{ width: '100%', zIndex: 999 }}
          loading={isLoadingDomains}
          defaultValue={selectedDomain}
          options={items}
          onChange={setSelectedDomain}
        />
      </InputGroupVertical>
    </>
  )
}

export default Domains
