import React from 'react'
import { FormControl, FormLabel, Box } from '@mui/joy/'
import { useTranslation } from 'react-i18next'

const InputGroupVertical = ({ text = '', children, sxText = '' }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormControl sx={{ gridColumn: '1/-1' }}>
        <FormLabel sx={sxText}>{t(text)}</FormLabel>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          {children}
        </Box>
      </FormControl>
    </>
  )
}

export default InputGroupVertical
