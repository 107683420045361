export const getRandomDigits = (length) => {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1),
  )
}

export const generatePassword = (
  length,
  uppercase = true,
  digits = true,
  special = false,
) => {
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz'
  const upperChars = lowerChars.toUpperCase()
  const numChars = '0123456789'
  const specialChars = '!@#$%^&*()-_=+[]{}|<>?'

  let chars = lowerChars
  let resultPassword = ''

  if (uppercase) {
    chars += upperChars
    resultPassword += upperChars[Math.floor(Math.random() * upperChars.length)]
  }

  if (digits) {
    chars += numChars
    resultPassword += numChars[Math.floor(Math.random() * numChars.length)]
  }

  if (special) {
    chars += specialChars
    resultPassword +=
      specialChars[Math.floor(Math.random() * specialChars.length)]
  }

  for (let i = resultPassword.length; i < length; i++) {
    const randomIdx = Math.floor(Math.random() * chars.length)
    resultPassword += chars[randomIdx]
  }

  return (resultPassword = resultPassword
    .split('')
    .sort(() => Math.random() - 0.5)
    .join(''))
}

export const wait = (ms) => {
  let timeoutId = null

  const promise = new Promise((resolve) => {
    timeoutId = setTimeout(resolve, ms)
  })

  promise.cancel = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }
  }

  return promise
}
