import React from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useSelector } from 'react-redux'
import { listItemButtonClasses } from '@mui/joy/ListItemButton'
import { List } from '@mui/joy'
import Container from './Container'
import { useAppDispatch } from '../../../../../store'
import { zoneInvocationGetAll } from '../../../../../store/zones'
import { setFetchedZonesInvocation } from '../../../../../store/zones/reducer'

const ListItems = ({ items, selectedItem, setSelectedItem }) => {
  const dispatch = useAppDispatch()
  const isLoading = useSelector(
    (state) => state.publishers.getAllData.isLoading,
  )

  const listSx = () => ({
    '.advertiser_item_name': {
      '--ListItem-plainActiveBg': 'transparent',
      [`& .${listItemButtonClasses.root}`]: {
        borderLeftColor: 'transparent',
      },
    },
    [`& .${listItemButtonClasses.root}`]: {
      borderRadius: 'var(--Button-radius, var(--joy-radius-sm))',
    },

    '--ListItem-paddingLeft': '16px',
    '--ListItem-startActionWidth': '0px',
    '--ListItem-startActionTranslateX': '-50%',
  })

  const getZones = async (publisher_id) => {
    dispatch(zoneInvocationGetAll({ publisher_id })).then((result) => {
      if (result.length > 0) {
        dispatch(setFetchedZonesInvocation({ [publisher_id]: result }))
      }
    })
  }

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      {items?.length ? (
        <List sx={listSx}>
          {items &&
            items.map((i) => (
              <Container
                key={i.id}
                title={i.name}
                content_id={i.id}
                active={selectedItem === i.id}
                onClickHandler={() => {
                  getZones(i.id)
                  setSelectedItem(i.id)
                }}
              />
            ))}
        </List>
      ) : (
        !!isLoading && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '20dvh',
            }}
          >
            <CircularProgress size="sm" />
          </Box>
        )
      )}
    </Box>
  )
}

export default ListItems
