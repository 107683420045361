import * as auth from './auth'

import * as advertisersAdd from './advertisers/add'
import * as advertisersGet from './advertisers/get'
import * as advertisersEdit from './advertisers/edit'
import * as advertisersDel from './advertisers/delete'

import * as categoriesAdd from './categories/add'
import * as categoriesGet from './categories/get'
import * as categoriesEdit from './categories/edit'
import * as categoriesDel from './categories/delete'

import * as usersAdd from './users/add'
import * as usersGet from './users/get'
import * as usersEdit from './users/edit'
import * as usersDel from './users/delete'

import * as campaignsAdd from './campaigns/add'
import * as campaignsGet from './campaigns/get'
import * as campaignsEdit from './campaigns/edit'
import * as campaignsDel from './campaigns/delete'

import * as bannersAdd from './banners/add'
import * as bannersGet from './banners/get'
import * as bannersEdit from './banners/edit'
import * as bannersDel from './banners/delete'

import * as customAdd from './custom/add'
import * as customGet from './custom/get'
import * as customDel from './custom/delete'
import * as customEdit from './custom/edit'

import * as templatesAdd from './templates/add'
import * as templatesGet from './templates/get'
import * as templatesDel from './templates/delete'
import * as templatesEdit from './templates/edit'

import * as rotationsAdd from './rotations/add'
import * as rotationsGet from './rotations/get'
import * as rotationsDel from './rotations/delete'
import * as rotationsEdit from './rotations/edit'

import * as reTargetAdd from './retarget/add'
import * as reTargetEdit from './retarget/edit'
import * as reTargetGet from './retarget/get'
import * as reTargetDel from './retarget/delete'

import * as publishersAdd from './publishers/add'
import * as publishersGet from './publishers/get'
import * as publishersDel from './publishers/delete'
import * as publishersEdit from './publishers/edit'

import * as zonesAdd from './zones/add'
import * as zonesGet from './zones/get'
import * as zonesDel from './zones/delete'
import * as zonesEdit from './zones/edit'

import * as statisticGet from './statistic/get'
import * as domainsGet from './domains/get'

const api = {
  auth,
  bannersAdd,
  bannersGet,
  bannersEdit,
  bannersDel,
  campaignsAdd,
  campaignsGet,
  campaignsEdit,
  campaignsDel,
  advertisersAdd,
  advertisersGet,
  advertisersEdit,
  advertisersDel,
  categoriesAdd,
  categoriesGet,
  categoriesEdit,
  categoriesDel,
  usersAdd,
  usersGet,
  usersEdit,
  usersDel,
  reTargetAdd,
  reTargetEdit,
  reTargetGet,
  reTargetDel,
  templatesAdd,
  templatesGet,
  templatesDel,
  templatesEdit,
  rotationsAdd,
  rotationsGet,
  rotationsDel,
  rotationsEdit,
  publishersAdd,
  publishersGet,
  publishersDel,
  publishersEdit,
  zonesAdd,
  zonesGet,
  zonesDel,
  zonesEdit,
  customAdd,
  customGet,
  customDel,
  customEdit,
  statisticGet,
  domainsGet,
}

export default api
