import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DomainsState {
  domains: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
    domains: string[] | []
  }
}

const initialState: DomainsState = {
  domains: {
    isLoading: false,
    success: false,
    error: false,
    domains: [],
  },
}

export const reducer = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    domainsGetStart: (state): DomainsState => ({
      ...state,
      domains: {
        ...state.domains,
        isLoading: true,
        success: false,
        error: false,
      },
    }),
    domainsGetSuccess: (state, action: PayloadAction<any>): DomainsState => {
      const domains = action.payload

      return {
        ...state,
        domains: {
          ...state.domains,
          domains: domains,
          isLoading: false,
          success: true,
          error: false,
        },
      }
    },
    domainsGetFailure: (
      state,
      action: PayloadAction<string>,
    ): DomainsState => ({
      ...state,
      domains: {
        ...state.domains,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
})

export const { domainsGetStart, domainsGetSuccess, domainsGetFailure } =
  reducer.actions

export default reducer.reducer
