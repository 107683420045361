import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdvertisersState, Advertiser } from './types'

const initialState: AdvertisersState = {
  allData: {
    advertisersById: {},
    allAdvertiserIds: [],
    isLoading: false,
    success: false,
    error: null,
  },
  sharingData: {
    advertisersById: {},
    allAdvertiserIds: [],
    isLoading: false,
    success: false,
    error: null,
    delete: false,
  },
  advertiserBudget: {
    budget: {
      advertiser_id: null,
      total_budget: 0,
      budget_history: [],
    },
    isLoading: false,
    success: false,
    error: null,
  },
}

export const reducer = createSlice({
  name: 'advertiserState',
  initialState,
  reducers: {
    // Set isLoading to true
    advertiserFetchStart: (state): AdvertisersState => ({
      ...state,
      allData: {
        ...state.allData,
        isLoading: true,
        success: null,
        error: null,
      },
    }),

    // Get all advertisers
    advertiserGetAllDone: (
      state,
      { payload: { error, rows } }: PayloadAction<any>,
    ): AdvertisersState => {
      if (!error) {
        const advertisersArray = rows

        const advertisersById = advertisersArray.reduce(
          (
            acc: { [x: number]: any },
            advertiser: { advertiser_id: number },
          ) => {
            acc[advertiser.advertiser_id] = advertiser
            return acc
          },
          {} as { [key: number]: Advertiser },
        )

        const allAdvertiserIds = Object.keys(advertisersById).map(Number)

        return {
          ...state,
          allData: {
            advertisersById,
            allAdvertiserIds,
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: {
            ...state.allData,
            isLoading: false,
            success: false,
            error,
          },
        }
      }
    },

    // Set active
    advertiserSetActiveDone: (
      state,
      { payload: { error, advertiser_id, active } }: PayloadAction<any>,
    ): AdvertisersState => {
      if (!error) {
        return {
          ...state,
          allData: {
            advertisersById: {
              ...state.allData.advertisersById,
              [advertiser_id]: {
                ...state.allData.advertisersById[advertiser_id],
                active,
              },
            },
            allAdvertiserIds: state.allData.allAdvertiserIds,
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: { ...state.allData, error, success: false },
        }
      }
    },

    // Add advertiser
    advertiserAddDone: (
      state,
      { payload: { success, result, error } }: PayloadAction<any>,
    ): AdvertisersState => ({
      ...state,
      allData: {
        advertisersById: success
          ? { ...state.allData.advertisersById, [result.advertiser_id]: result }
          : state.allData.advertisersById,
        allAdvertiserIds: success
          ? [...state.allData.allAdvertiserIds, result.advertiser_id]
          : state.allData.allAdvertiserIds,
        isLoading: false,
        success,
        error,
      },
    }),

    // Delete advertiser
    advertiserDeleteDone: (
      state,
      { payload: { advertiser_id, error } }: PayloadAction<any>,
    ): AdvertisersState => {
      if (!error) {
        const { [advertiser_id]: _, ...newById } = state.allData.advertisersById

        return {
          ...state,
          allData: {
            advertisersById: newById,
            allAdvertiserIds: state.allData.allAdvertiserIds.filter(
              (id) => id !== advertiser_id,
            ),
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: {
            ...state.allData,
            isLoading: false,
            success: false,
            error,
          },
        }
      }
    },

    // Edit advertiser
    advertiserEditDone: (
      state,
      {
        payload: { success, advertiser_id, error, ...data },
      }: PayloadAction<any>,
    ): AdvertisersState => ({
      ...state,
      allData: {
        advertisersById: success
          ? {
              ...state.allData.advertisersById,
              [advertiser_id]: {
                ...state.allData.advertisersById[advertiser_id],
                ...data,
              },
            }
          : state.allData.advertisersById,
        allAdvertiserIds: state.allData.allAdvertiserIds,
        isLoading: false,
        success,
        error,
      },
    }),

    // Set isLoading to true (sharing)
    advertiserSharingFetchStart: (state): AdvertisersState => ({
      ...state,
      sharingData: {
        ...state.sharingData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Get all sharing advertisers
    advertiserGetAllSharingDone: (
      state,
      { payload: { error, rows, ...rest } }: PayloadAction<any>,
    ): AdvertisersState => {
      if (!error) {
        const advertisersArray = rows
        const advertisersById = advertisersArray.reduce(
          (acc: { [x: number]: any }, advertiser: { user_id: number }) => {
            acc[advertiser.user_id] = advertiser
            return acc
          },
          {} as { [key: number]: Advertiser },
        )

        const allAdvertiserIds = Object.keys(advertisersById).map(Number)

        return {
          ...state,
          sharingData: {
            ...rest,
            advertisersById,
            allAdvertiserIds,
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          sharingData: {
            ...state.sharingData,
            isLoading: false,
            success: false,
            error,
          },
        }
      }
    },

    // Add sharing advertiser
    advertiserAddSharingDone: (
      state,
      { payload: { success, result, error } }: PayloadAction<any>,
    ): AdvertisersState => ({
      ...state,
      sharingData: {
        advertisersById: success
          ? {
              ...state.sharingData.advertisersById,
              [result.advertiser_id]: result,
            }
          : state.sharingData.advertisersById,
        allAdvertiserIds: success
          ? [...state.sharingData.allAdvertiserIds, result.advertiser_id]
          : state.sharingData.allAdvertiserIds,
        isLoading: false,
        success: success,
        error: error,
        delete: state.sharingData.delete,
      },
    }),

    // Delete sharing advertiser
    advertiserSharingDeleteDone: (
      state,
      { payload: { success, advertiser_id, error } }: PayloadAction<any>,
    ): AdvertisersState => {
      if (!error) {
        const { [advertiser_id]: _, ...newById } =
          state.sharingData.advertisersById

        return {
          ...state,
          sharingData: {
            advertisersById: newById,
            allAdvertiserIds: state.sharingData.allAdvertiserIds.filter(
              (id) => id !== advertiser_id,
            ),
            isLoading: false,
            success: true,
            error: false,
            delete: state.sharingData.delete,
          },
        }
      } else {
        return {
          ...state,
          sharingData: {
            ...state.sharingData,
            isLoading: false,
            success: false,
            error,
          },
        }
      }
    },
    advertiserGetBudgetFetchStart: (state): AdvertisersState => ({
      ...state,
      advertiserBudget: {
        ...state.advertiserBudget,
        isLoading: true,
        success: null,
        error: null,
      },
    }),
    advertiserGetBudget: (
      state,
      {
        payload: { advertiser_id, total_budget, budget_history },
      }: PayloadAction<any>,
    ): AdvertisersState => ({
      ...state,
      advertiserBudget: {
        budget: {
          advertiser_id,
          total_budget,
          budget_history,
        },
        isLoading: false,
        success: false,
        error: false,
      },
    }),
    advertiserGetBudgetFailure: (state, action): AdvertisersState => ({
      ...state,
      advertiserBudget: {
        ...state.advertiserBudget,
        isLoading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
})

export const {
  advertiserFetchStart,
  advertiserAddDone,
  advertiserGetAllDone,
  advertiserSetActiveDone,
  advertiserDeleteDone,
  advertiserEditDone,
  advertiserSharingFetchStart,
  advertiserAddSharingDone,
  advertiserGetAllSharingDone,
  advertiserSharingDeleteDone,
  advertiserGetBudgetFetchStart,
  advertiserGetBudget,
  advertiserGetBudgetFailure,
} = reducer.actions

export default reducer.reducer
