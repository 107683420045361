import * as React from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Button, Input, Select, Switch } from 'antd'
import { Add, ContentCopyRounded, PersonRounded } from '@mui/icons-material'

import FormItem from '../../../layout/FormItem'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from '../../../layout/JoyToaster'

const UserRoles = require('../../../configs/user_roles.json')

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const [index, setIndex] = React.useState(0)
  const { arr, set } = values
  const checked = arr.active === 1

  const handleCopy = React.useCallback(() => {
    toast.success(t('copied'))
  }, [t])

  return (
    <Accordion
      sx={{ borderBottom: 0 }}
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>
          {t('add_advertiser_basic')}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <FormItem
            sx2={{ width: '100%', display: 'flex', alignContent: 'center' }}
            text={'users_table_email'}
            component={
              <Input
                value={arr.email || ''}
                onChange={(e) => {
                  set({
                    ...arr,
                    ...{ email: e.target.value },
                  })
                }}
              />
            }
          />
          <CopyToClipboard text={arr.email || ''} onCopy={handleCopy}>
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              disabled={!arr.email}
            />
          </CopyToClipboard>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <FormItem
            sx2={{ width: '100%', display: 'flex', alignContent: 'center' }}
            text={'users_table_password'}
            component={
              <Input.Password
                visibilityToggle
                value={arr.password || ''}
                onChange={(e) => {
                  set({
                    ...arr,
                    ...{ password: e.target.value },
                  })
                }}
              />
            }
          />
          <CopyToClipboard text={arr.password || ''} onCopy={handleCopy}>
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              disabled={!arr.password}
            />
          </CopyToClipboard>
        </Box>

        <FormItem
          text={'users_table_role'}
          component={
            <Select
              options={Object.keys(UserRoles).map((item) => ({
                label: UserRoles[item],
                value: item,
              }))}
              value={`${arr.role_id}`}
              style={{ width: '100%' }}
              onChange={(val) => {
                set({
                  ...arr,
                  ...{ role_id: parseInt(val) },
                })
              }}
            />
          }
        />

        <FormItem
          text={'add_advertiser_basic_active'}
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                size="small"
                checked={checked}
                onChange={(e) => {
                  set({
                    ...arr,
                    ...{ active: e ? 1 : 0 },
                  })
                }}
              />
            </Box>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
