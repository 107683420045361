import React from 'react'
import { ListItem, ListItemButton } from '@mui/joy'

const Container = ({
  getItems,
  active,
  content_id,
  templateItem,
  title,
  onClickHandler,
}) => {
  return (
    <ListItem
      sx={{
        ml: '20px',
        '&:hover > div > a  svg': {
          opacity: 1,
        },
      }}
      nested
    >
      <ListItem className={'advertiser_item_name'}>
        <ListItemButton
          selected={active}
          sx={{
            ml: '-36px',
            borderRadius: '0!important',
            pl: getItems ? '30px' : '15px',
            fontSize: 12,
            [`&.active`]: {
              background: '#fff',
            },
          }}
          onClick={onClickHandler}
        >
          {title}
        </ListItemButton>
      </ListItem>
    </ListItem>
  )
}

export default Container
