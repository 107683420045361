import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

import Content from '../components/Content'
import ErrorPage from './ErrorPage'
import Login from './Login'

import Advertisers from './Advertisers'
import Publishers from './Publishers'
import Statistics from './Statistics'
import Rotation from './Rotation/'
import Fields from './Fields'
import Params from './Params'
import Categories from './Categories'
import Templates from './Templates'
import ReTarget from './ReTarget'
import Users from './Users'

const Pages = (props) => {
  const isLoggedIn = useSelector((state) => !!state.auth.authData.accessToken)
  const profile = useSelector((state) => state.auth.profileData.profile)

  const page = (component) => {
    const error = !!props.profile.error
    const loading =
      (!props.profile || !props.profile.data || props.profile.data.isLoading) &&
      !error

    if (loading) {
      return null
    } else if (!isLoggedIn) {
      return <Navigate to={'/login'} />
    } else {
      return component
    }
  }

  return (
    <Routes>
      <Route path="/" element={<Content />}>
        {/* Main page */}
        <Route
          index
          element={isLoggedIn ? <ErrorPage /> : <Navigate to="/login" />}
        />

        {/* Other pages */}
        <Route path="/advertisers" element={page(<Advertisers />)} />
        <Route path="/advertisers/:page" element={page(<Advertisers />)} />
        <Route
          path="/advertisers/:page/:pageId"
          element={page(<Advertisers />)}
        />
        <Route
          path="/advertisers/:page/:event/:pageId"
          element={page(<Advertisers />)}
        />
        <Route
          path="/advertisers/:page/:event/:pageId/:campaignId"
          element={page(<Advertisers />)}
        />

        <Route path="/publishers" element={page(<Publishers />)} />
        <Route path="/publishers/:page" element={page(<Publishers />)} />
        <Route
          path="/publishers/:page/:pageId"
          element={page(<Publishers />)}
        />
        <Route
          path="/publishers/:page/:event/:pageId"
          element={page(<Publishers />)}
        />
        <Route
          path="/publishers/:page/:event/:pageId/:zoneId"
          element={page(<Publishers />)}
        />

        <Route path="/categories" element={page(<Categories />)} />
        <Route path="/categories/:page" element={page(<Categories />)} />
        <Route
          path="/categories/:page/:pageId"
          element={page(<Categories />)}
        />

        <Route
          path="/users"
          element={profile && profile.role_id === 1 ? page(<Users />) : ''}
        />
        <Route
          path="/users/:page"
          element={profile && profile.role_id === 1 ? page(<Users />) : ''}
        />
        <Route
          path="/users/:page/:pageId"
          element={profile && profile.role_id === 1 ? page(<Users />) : ''}
        />

        <Route path="/statistics" element={page(<Statistics />)} />
        <Route path="/statistics/:page" element={page(<Statistics />)} />
        <Route
          path="/statistics/:page/:pageId"
          element={page(<Statistics />)}
        />

        <Route path="/fields" element={page(<Fields />)} />
        <Route path="/fields/:page" element={page(<Fields />)} />
        <Route path="/fields/:page/:pageId" element={page(<Fields />)} />

        <Route path="/params" element={page(<Params />)} />
        <Route path="/params/:page" element={page(<Params />)} />
        <Route path="/params/:page/:pageId" element={page(<Params />)} />

        <Route path="/templetes" element={page(<Templates />)} />
        <Route path="/templetes/:page" element={page(<Templates />)} />
        <Route path="/templetes/:page/:pageId" element={page(<Templates />)} />

        <Route path="/re-target" element={page(<ReTarget />)} />
        <Route path="/re-target/:page" element={page(<ReTarget />)} />
        <Route path="/re-target/:page/:pageId" element={page(<ReTarget />)} />

        <Route path="/rotation" element={page(<Rotation />)} />
        <Route path="/rotation/:page" element={page(<Rotation />)} />
        <Route path="/rotation/:page/:pageId" element={page(<Rotation />)} />

        {/* Error page*/}
        <Route path="*" element={isLoggedIn ? <ErrorPage /> : <ErrorPage />} />

        {/* Login */}
        <Route
          path="/login"
          element={
            !isLoggedIn || !profile ? (
              <Login />
            ) : (
              <Navigate to="/statistics/filter" />
            )
          }
        />
      </Route>
    </Routes>
  )
}

export default Pages
