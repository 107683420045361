import React, { useCallback, useState } from 'react'
import { Card, CardContent } from '@mui/joy/'
import { Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useColorScheme } from '@mui/joy/styles'
import Editor from '@monaco-editor/react'

import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Domains from './Domains'
import { toast } from '../../../../layout/JoyToaster'
import { ContentCopyRounded } from '@mui/icons-material'
import InputGroupVertical from '../../../../components/InputGroup'

const configEditor = {
  fixedOverflowWidgets: true,
  inlineSuggest: true,
  fontSize: '12px',
  formatOnType: true,
  readOnly: true,
  readOnlyMessage: { value: '' },
  autoClosingBrackets: true,
  scrollBeyondLastColumn: 0,
  scrollBeyondLastLine: false,
  wordWrap: 'off',
  acceptSuggestionOnEnter: 'off',
  contextmenu: false,
  minimap: { enabled: false },
  lineNumbers: 'off',
  folding: false,
  scrollPredominantAxis: false,
  smoothScrolling: false,
  overviewRulerBorder: false,
  renderLineHighlight: 'none',
  renderLineHighlightOnlyWhenFocus: false,
  lineDecorationsWidth: 0,
  stickyScroll: { enabled: false },
  guides: { indentation: false },
  link: false,
  scrollbar: {
    vertical: 'false',
    horizontal: 'auto',
    scrollByPage: false,
    alwaysConsumeMouseWheel: false,
    handleMouseWheel: false,
    useShadows: false,
  },
}
const Zones = ({ itemsInfo }) => {
  const { t } = useTranslation()
  const { mode } = useColorScheme()
  const [siteName, setSiteName] = useState('')
  const [selectedDomain, setSelectedDomain] = useState(0)
  const domains = useSelector((state) => state.domains.domains.domains)

  const handleCopy = useCallback(() => {
    toast.success(t('copied'))
  }, [t])

  const userPublisherKey = useSelector(
    (state) => state.users.getData.publisher_key,
  )

  const generateZoneCode = (zone) => {
    const cpHost = siteName
      ? `${userPublisherKey}|${siteName}`
      : userPublisherKey
    const cpDomain = siteName || ''

    // Handle VAST type (rotation_type === 1)
    if (zone.rotation_type === 1 && zone.vast) {
      return `https://${domains[selectedDomain]}/v/${zone.vast.v3}.xml?cp.host=${cpHost}&cp.domain=${cpDomain}`
    }

    // Handle other types (not VAST)
    if (zone.rotation_type !== 1 && zone.banners?.length > 0) {
      return `<ins class="${zone.client}" data-key="${zone.uuid}" data-cp-host="${cpHost}" data-cp-domain="${cpDomain}"></ins>`
    }

    return ''
  }

  const generateScriptCode = (zone) => {
    if (zone.rotation_type !== 1 && zone.banners?.length > 0) {
      // eslint-disable-next-line no-unused-vars
      const [_, script] = zone.banners[0]

      return `<script async src="https://${domains[selectedDomain]}/o/s/${script}"></script>`
    }
    return ''
  }

  const ZoneCodeBlock = ({ zone, index }) => {
    const code = generateZoneCode(zone)
    const scriptCode = generateScriptCode(zone)

    if (index === 0) {
      return (
        <>
          <InputGroupVertical
            text="ads_call_script"
            sxText={{ fontWeight: 'bold' }}
          >
            <Editor
              height="40px"
              language="html"
              theme={mode === 'dark' ? 'vs-dark' : 'vs-light'}
              value={scriptCode}
              options={configEditor}
              loading=""
            />
            <CopyToClipboard text={scriptCode || ''} onCopy={handleCopy}>
              <Button
                style={{ display: 'flex', alignItems: 'center' }}
                icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              ></Button>
            </CopyToClipboard>
          </InputGroupVertical>

          <InputGroupVertical
            text={zone.zone_name}
            sxText={{ fontWeight: 'bold' }}
          >
            <Editor
              height="40px"
              language="html"
              theme={mode === 'dark' ? 'vs-dark' : 'vs-light'}
              value={code}
              options={configEditor}
              loading=""
              className=""
            />
            <CopyToClipboard text={code || ''} onCopy={handleCopy}>
              <Button
                style={{ display: 'flex', alignItems: 'center' }}
                icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              ></Button>
            </CopyToClipboard>
          </InputGroupVertical>
        </>
      )
    }
    return (
      <InputGroupVertical text={zone.zone_name} sxText={{ fontWeight: 'bold' }}>
        <Editor
          height="40px"
          language="html"
          theme={mode === 'dark' ? 'vs-dark' : 'vs-light'}
          value={code}
          options={configEditor}
          loading=""
        />
        <CopyToClipboard text={code || ''} onCopy={handleCopy}>
          <Button
            style={{ display: 'flex', alignItems: 'center' }}
            icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
          ></Button>
        </CopyToClipboard>
      </InputGroupVertical>
    )
  }

  return (
    <>
      <Card
        variant="plain"
        sx={{
          maxHeight: 'max-content',
          maxWidth: '100%',
          overflow: 'auto',
          resize: 'none',
          background: 'transparent',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            gap: 1.5,
            paddingX: 4,
          }}
        >
          <Domains
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
            values={domains}
          />

          <InputGroupVertical text="site_name" sxText={{ fontWeight: 'bold' }}>
            <Input
              placeholder={''}
              value={siteName}
              onChange={(e) => {
                const input = e.target.value.trim()
                // eslint-disable-next-line no-useless-escape
                const urlNoProtocol = input.replace(/^https?\:\/\//i, '')
                setSiteName(urlNoProtocol)
              }}
              style={{ marginBottom: 16 }}
            />
            <CopyToClipboard text={siteName || ''} onCopy={handleCopy}>
              <Button
                disabled={!siteName}
                style={{ display: 'flex', alignItems: 'center' }}
                icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              ></Button>
            </CopyToClipboard>
          </InputGroupVertical>

          {itemsInfo.map((zone, index) => (
            <ZoneCodeBlock key={zone.zone_id} zone={zone} index={index} />
          ))}
        </CardContent>
      </Card>
    </>
  )
}

export default Zones
