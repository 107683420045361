import React, { useCallback, useState } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Button, Input, Select, Switch } from 'antd'
import {
  Add,
  PersonRounded,
  ContentCopyRounded,
  AutorenewRounded,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import FormItem from '../../../layout/FormItem'
import { generatePassword, getRandomDigits } from '../../../../utils'
import { toast } from '../../../layout/JoyToaster'

const UserRoles = require('../../../configs/user_roles.json')

const getRandomEmail = () => `p${getRandomDigits(8)}@adangle.io`

const Basic = ({ values }) => {
  const { t } = useTranslation()
  const { arr, set } = values

  const [accordionIndex, setAccordionIndex] = useState(0)
  const [isPublisherUserRole, setIsPublisherUserRole] = useState(false)

  const handleFormUpdate = useCallback(
    (updates) => {
      set((prev) => ({
        ...prev,
        ...updates,
      }))
    },
    [set],
  )

  const generateNewEmail = useCallback(() => {
    const email = getRandomEmail()
    handleFormUpdate({ email })
  }, [handleFormUpdate])

  const generateNewPassword = useCallback(() => {
    const password = generatePassword(12)
    handleFormUpdate({ password })
  }, [handleFormUpdate])

  const handleRoleChange = useCallback(
    (val) => {
      const isPublisher = UserRoles[val] === 'Publisher'
      setIsPublisherUserRole(isPublisher)

      if (isPublisher) {
        const email = getRandomEmail()
        const password = generatePassword(12)
        handleFormUpdate({
          role_id: parseInt(val),
          email,
          password,
        })
      } else {
        handleFormUpdate({
          role_id: parseInt(val),
          email: '',
          password: '',
        })
      }
    },
    [handleFormUpdate],
  )

  const handleCopy = useCallback(() => {
    toast.success(t('copied'))
  }, [t])

  return (
    <Accordion
      expanded={accordionIndex === 0}
      onChange={(_, expanded) => {
        setAccordionIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <PersonRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('add_user_basic')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <FormItem
            sx2={{ width: '100%', display: 'flex', alignContent: 'center' }}
            text="users_table_email"
            component={
              <Input
                value={arr.email || ''}
                onChange={(e) => handleFormUpdate({ email: e.target.value })}
              />
            }
          />

          {isPublisherUserRole && (
            <Button
              onClick={generateNewEmail}
              style={{
                padding: '2px 7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AutorenewRounded sx={{ fontSize: 16 }} />
            </Button>
          )}

          <CopyToClipboard text={arr.email || ''} onCopy={handleCopy}>
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              disabled={!arr.email}
            ></Button>
          </CopyToClipboard>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <FormItem
            sx2={{ width: '100%', display: 'flex', alignContent: 'center' }}
            text="users_table_password"
            component={
              <Input.Password
                value={arr.password || ''}
                onChange={(e) =>
                  handleFormUpdate({ password: e.target.value.trim() })
                }
                visibilityToggle
              />
            }
          />

          {isPublisherUserRole && (
            <Button
              onClick={generateNewPassword}
              style={{
                padding: '2px 7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AutorenewRounded sx={{ fontSize: 16 }} />
            </Button>
          )}

          <CopyToClipboard text={arr.password || ''} onCopy={handleCopy}>
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              icon={<ContentCopyRounded sx={{ color: 'inherit' }} />}
              disabled={!arr.password}
            ></Button>
          </CopyToClipboard>
        </Box>

        <FormItem
          text="users_table_role"
          component={
            <Select
              options={Object.keys(UserRoles).map((item) => ({
                label: UserRoles[item],
                value: item,
              }))}
              style={{ width: '100%' }}
              onChange={handleRoleChange}
              value={UserRoles[arr.role_id] || undefined}
            />
          }
        />

        <FormItem
          text="users_table_status"
          component={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Switch
                size="small"
                checked={arr.active}
                onChange={(checked) => handleFormUpdate({ active: checked })}
              />
            </Box>
          }
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default Basic
