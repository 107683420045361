import {
  Col,
  Row,
  Statistic,
  Button,
  Space,
  Table,
  Empty,
  Input,
  Tooltip,
  DatePicker,
  ConfigProvider,
  InputNumber,
} from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import localeRu from 'antd/locale/ru_RU'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import utc from 'dayjs/plugin/utc'
import { addDepositAdvertiser } from '../../../../../../store/advertisers'
import { useAppDispatch } from '../../../../../../store'

import { toast } from '../../../../../layout/JoyToaster'

dayjs.extend(utc)
const dateFormat = 'YYYY-MM-DD HH:mm'

export const BalanceContainer = ({ budgetInfo, advertiserId }) => {
  const [amountInput, setAmountInput] = useState(null)
  const [touched, setTouched] = useState(false)
  const [commentInput, setCommentInput] = useState(null)
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const inputRef = useRef(null)

  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const dataSource = budgetInfo.budget_history

  const handleBlur = () => {
    setTouched(true)
  }

  const handleFocus = () => {
    inputRef.current.focus({
      cursor: 'all',
    })
  }

  const handleDateChange = (date) => {
    if (date) {
      const utcTimestamp = date.utc().format('YYYY-MM-DD HH:mm:ss.SSS')
      setSelectedDate(utcTimestamp)

      // const localDate = date.format(dateFormat)
      // console.log('Local date for display:', localDate)
    }
  }

  const addDepositHandler = useCallback(() => {
    dispatch(
      addDepositAdvertiser({
        advertiser_id: advertiserId,
        created_at: selectedDate,
        budget_amount: amountInput,
        comment: commentInput ? commentInput : undefined,
      }),
    ).then((result) => {
      if (result.status !== 200) {
        toast.error(result.response.data.errorText)
      } else {
        toast.success(result.data.value)
      }
    })
  }, [advertiserId, amountInput, commentInput, dispatch, selectedDate])

  const handleInputAmountChange = (value) => {
    setAmountInput(value)
    // if (value) {
    //   console.log('Entered amount:', value)
    // }
  }

  const columns = [
    {
      title: t('replenishment_amount'),
      dataIndex: 'budget_amount',
      key: 'budget_amount',
      width: '33.3%',
      ellipsis: 'true',
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      key: 'comment',
      width: '33.3%',
      ellipsis: 'true',
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('replenishment_date'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: '33.3%',
      render: (text) => dayjs(text).format(dateFormat),
    },
  ]

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Statistic
          title={t('budget_total')}
          value={budgetInfo?.total_budget || 0}
        />

        <Table
          title={() => (
            <Row>
              <Col span={18}>
                <Space
                  direction="horizontal"
                  style={{ width: '100%' }}
                  styles={{
                    item: {
                      width: '100%',
                    },
                  }}
                >
                  <InputNumber
                    ref={inputRef}
                    style={{ width: '100%' }}
                    value={amountInput}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')
                    }
                    placeholder={t('enter_amount')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    precision={3}
                    onBlur={handleBlur}
                    onChange={handleInputAmountChange}
                    status={touched && !amountInput ? 'error' : ''}
                  />
                  <Input
                    width={'100%'}
                    placeholder={t('enter_comment')}
                    value={commentInput}
                    onChange={(e) => {
                      const input = e.target.value.trim()
                      setCommentInput(input)
                    }}
                  />
                  <ConfigProvider
                    locale={i18n.language === 'ru' ? localeRu : {}}
                  >
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      style={{ display: 'flex', flex: 1 }}
                      format={dateFormat}
                      defaultValue={selectedDate}
                      onChange={handleDateChange}
                    />
                  </ConfigProvider>
                </Space>
              </Col>
              <Col span={6} style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  disabled={!amountInput || !selectedDate}
                  onClick={addDepositHandler}
                >
                  {t('add')}
                </Button>
              </Col>
            </Row>
          )}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={{
            locale: {
              items_per_page: `/ ${t('per_page_items')}`,
            },
            defaultPageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '15', '30'],
          }}
          scroll={{ y: 55 * 5 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('no_data')}
              >
                <Button onClick={handleFocus}>{t('add')}</Button>
              </Empty>
            ),
          }}
          size="small"
        />
      </Col>
    </Row>
  )
}
