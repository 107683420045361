import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  domainsGetStart,
  domainsGetSuccess,
  domainsGetFailure,
} from './reducer'

export const getDomains =
  () =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(domainsGetStart())
      const result = await api.domainsGet.getDomains()
      dispatch(domainsGetSuccess(result.data.domains))
      return result.data
    } catch (e: any) {
      dispatch(domainsGetFailure(e.message))
      return false
    }
  }
